export enum ImageModerationLabels {
  nudity = 'nudity',
  suggestive = 'suggestive',
  gore = 'gore',
  hate = 'hate',
  violence = 'violence',
  weapon = 'weapon',
  smoking = 'smoking',
  drugs = 'drugs',
  alcohol = 'alcohol',
  text = 'text',
}

export enum TextImageModerationLabels {
  text = 'text',
}

export enum NsfwImageModerationLabels {
  nudity = 'nudity',
  suggestive = 'suggestive',
  gore = 'gore',
  hate = 'hate',
  violence = 'violence',
  weapon = 'weapon',
  smoking = 'smoking',
  drugs = 'drugs',
  alcohol = 'alcohol',
}

export enum NsfwImageModerationLabelDescriptions {
  nudity = 'Exposed male or female genitalia, female nipples, sexual acts.',
  suggestive = 'Partial nudity, kissing.',
  gore = 'Blood, wounds, death.',
  hate = 'Symbols related to nazi, terrorist groups, white supremacy and more.',
  violence = 'Graphic violence, causing harm, weapons, self-harm.',
  weapon = 'Weapons either in use or displayed.',
  smoking = 'Smoking or smoking related content.',
  drugs = 'Drugs such as pills.',
  alcohol = 'Alcohol or alcohol related content.',
}

export const nsfwImageModelId = 'image';
export const textImageModelId = 'image_text';
