import {
  DISCRIMINATION_LABELS,
  NSFW_LABELS,
  PROPRIETY_LABELS,
  QUALITY_LABELS,
  SELF_HARM_LABELS,
  SENTIMENT_LABELS,
  SEXUAL_LABELS,
  TOXICITY_LABELS,
  VIOLENCE_LABELS,
} from '@/components/app/ModelComponents/Labels';
import {
  NsfwImageModerationLabelDescriptions,
  TextImageModerationLabels,
  textImageModelId,
} from '@/server/logic/analyze/image/ImageModerationTypes';
import { PII_LABEL_DECSCRIPTIONS } from '@/server/logic/analyze/pii';
import {
  AccountPlan,
  ModelStatus,
  ModelType,
  WebhookType,
} from '@prisma/client';

export const DATA_TYPES = Object.freeze({
  EMAIL: 'email',
  PHONE: 'phone',
  URL: 'url',
  ADDRESS: 'address',
  NAME: 'name',
  USERNAME: 'username',
  PROFANITY: 'profanity',
  SENSITIVE: 'sensitive',
  WORDLIST: 'wordlist',
});

export const ANALYSIS_TYPES = Object.freeze({
  LANGUAGE: 'language',
  TOXICITY: 'toxicity',
  SENTIMENT: 'sentiment',
  QUALITY: 'quality',
  PROPRIETY: 'propriety',
  NSFW: 'nsfw',
  SELF_HARM: 'self_harm',
  UNSAFE: 'unsafe',
  TOXIC_GERMAN: 'toxic_german',
  PII: 'pii',
  SEXUAL: 'sexual',
  VIOLENCE: 'violence',
  DISCRIMINATION: 'discrimination', // hate and harassment
  IMAGE: 'image',
  IMAGE_TEXT: textImageModelId,
});

const defaultPlanValues: Partial<AccountPlan> & {} = {
  customModelsIncluded: 1,
  aiAgentsIncluded: 1,
  autoAnnotationsIncluded: 100,
};

const DEV_PLANS = Object.freeze({
  TRIAL: {
    ...defaultPlanValues,
    id: 'TRIAL',
    name: 'Free trial',
    maxRequests: 100,
    maxProjects: 1,
    maxModels: -1,
    maxWordlists: 2,
    characterLimit: 20000,
    priceId: null,
    productId: null,
    softLimitPercentage: 0,
    moderationDashboardIncluded: true,
    analyticsIncluded: true,
    maxRequestsPerMinute: 300,
    membersIncluded: 1,
  },
  FREE: {
    ...defaultPlanValues,
    id: 'FREE',
    name: 'Free plan',
    priceId: 'price_1KcmdbEVN107xxO1fJ0vhJra',
    productId: null,
    maxRequests: 100,
    maxProjects: 1,
    maxModels: -1,
    maxWordlists: 2,
    characterLimit: 10000,
    softLimitPercentage: 0,
    maxRequestsPerMinute: 300,
    moderationDashboardIncluded: false,
    analyticsIncluded: false,
    membersIncluded: 1,
  },
  LITE: {
    ...defaultPlanValues,
    id: 'LITE',
    name: 'Lite',
    priceId: 'price_1IHWTkEVN107xxO1mpc1KJWv',
    productId: 'prod_ItJ5vcwHchM99J',
    maxRequests: 10000,
    maxProjects: 1,
    maxModels: -1,
    maxWordlists: 2,
    characterLimit: 10000,
    softLimitPercentage: 0,
    maxRequestsPerMinute: 300,
    moderationDashboardIncluded: false,
    analyticsIncluded: false,
    membersIncluded: 1,
  },
  STARTER: {
    ...defaultPlanValues,
    id: 'STARTER',
    name: 'Starter',
    priceId: 'price_1IHWTxEVN107xxO137Kp2Pqp',
    productId: 'prod_ItJ6zeznpqUGB1',
    maxRequests: 50000,
    maxProjects: 3,
    maxModels: -1,
    maxWordlists: 6,
    characterLimit: 10000,
    softLimitPercentage: 0,
    maxRequestsPerMinute: 300,
    moderationDashboardIncluded: false,
    analyticsIncluded: false,
    membersIncluded: 1,
  },
  BUSINESS: {
    ...defaultPlanValues,
    id: 'BUSINESS',
    name: 'Business',
    priceId: 'price_1IHWUAEVN107xxO1GGu4RpZz',
    productId: 'prod_ItJ62vhlhVmrOg',
    maxRequests: 500000,
    maxProjects: 5,
    maxModels: -1,
    maxWordlists: 10,
    characterLimit: 10000,
    softLimitPercentage: 0,
    maxRequestsPerMinute: 300,
    moderationDashboardIncluded: false,
    analyticsIncluded: false,
    membersIncluded: 3,
  },
  // 2023 plans
  LITE_2: {
    ...defaultPlanValues,
    id: 'LITE_2',
    name: 'Lite',
    maxRequests: 10000,
    maxProjects: 2,
    maxModels: -1,
    maxWordlists: 2,
    characterLimit: 10000,
    priceId: 'price_1N1MhqEVN107xxO19QDTDKS4',
    priceIdYearly: 'price_1N1MhqEVN107xxO1ESvPhPwy',
    productId: 'prod_NmwbdmStFvaBlS',
    softLimitPercentage: 0,
    maxRequestsPerMinute: 120,
    maxPaidRequests: 0,
    customModelsIncluded: 2,
    sumModelUsageEnabled: false,
    analyticsIncluded: false,
    paygAllowed: true,
    autoAnnotationsIncluded: 2000,
    membersIncluded: 1,
  },
  BUSINESS_3: {
    ...defaultPlanValues,
    id: 'BUSINESS_3',
    name: 'Business',
    maxRequests: 100000,
    maxProjects: 5,
    maxModels: -1,
    maxWordlists: 10,
    characterLimit: 10000,
    priceId: 'price_1N1MhCEVN107xxO17KBbD2b5',
    priceIdYearly: 'price_1N1MhCEVN107xxO1fqa9Tq9T',
    productId: 'prod_NmwaPorBmo5XPZ',
    softLimitPercentage: 0,
    maxRequestsPerMinute: 120,
    maxPaidRequests: 0,
    customModelsIncluded: 3,
    sumModelUsageEnabled: false,
    analyticsIncluded: true,
    moderationDashboardIncluded: true,
    paygAllowed: true,
    autoAnnotationsIncluded: 10000,
    membersIncluded: 3,
  },
  CUSTOM_CP_LEGACY: {
    //  this one should only allow toxicity, nsfw, and profanity, no custom models or entity recognition
    id: 'CUSTOM_CP_LEGACY',
    name: 'Club Penguin Legacy',
    maxRequests: 1000000,
    maxProjects: 5,
    maxModels: 3,
    maxWordlists: 10,
    characterLimit: 10000,
    priceId: 'price_1NuCtAEVN107xxO1GFtsTUox',
    productId: 'prod_Ohc7jjPLZHrReJ',
    softLimitPercentage: 0,
    maxRequestsPerMinute: 1500,
    maxPaidRequests: 0,
    customModelsIncluded: 0,
    sumModelUsageEnabled: false,
    analyticsIncluded: true,
    moderationDashboardIncluded: true,
    paygAllowed: true,
    autoAnnotationsIncluded: 0,
    aiAgentsIncluded: 0,
    membersIncluded: 1,
  },
});

const PROD_PLANS = Object.freeze({
  TRIAL: {
    ...defaultPlanValues,
    id: 'TRIAL',
    name: 'Free trial',
    maxRequests: 100,
    maxProjects: 1,
    maxModels: -1,
    maxWordlists: 2,
    characterLimit: 20000,
    softLimitPercentage: 0,
    maxRequestsPerMinute: 300,
    priceId: null,
    productId: null,
    moderationDashboardIncluded: true,
    analyticsIncluded: true,
    membersIncluded: 1,
  },
  FREE: {
    ...defaultPlanValues,
    id: 'FREE',
    name: 'Free plan',
    priceId: 'price_1KckKeEVN107xxO1mPqR5T9W',
    productId: 'prod_LJN4W3pasZFHoy',
    maxRequests: 100,
    maxProjects: 1,
    maxModels: -1,
    maxWordlists: 2,
    characterLimit: 10000,
    softLimitPercentage: 0,
    maxRequestsPerMinute: 300,
    membersIncluded: 1,
  },
  LITE: {
    ...defaultPlanValues,
    id: 'LITE',
    name: 'Lite',
    priceId: 'price_1Io6rDEVN107xxO1T5gSJIPA',
    productId: 'prod_ItAntzuvspssyP',
    maxRequests: 10000,
    maxProjects: 1,
    maxModels: -1,
    maxWordlists: 2,
    characterLimit: 10000,
    softLimitPercentage: 0,
    maxRequestsPerMinute: 300,
    moderationDashboardIncluded: false,
    analyticsIncluded: false,
    membersIncluded: 1,
  },
  STARTER: {
    ...defaultPlanValues,
    id: 'STARTER',
    name: 'Starter',
    priceId: 'price_1Io6rcEVN107xxO1V3DIF69t',
    productId: 'prod_ItAoEacqJIwInb',
    maxRequests: 50000,
    maxProjects: 3,
    maxModels: -1,
    maxWordlists: 6,
    characterLimit: 10000,
    softLimitPercentage: 0,
    maxRequestsPerMinute: 300,
    moderationDashboardIncluded: false,
    analyticsIncluded: false,
    membersIncluded: 1,
  },
  BUSINESS: {
    ...defaultPlanValues,
    id: 'BUSINESS',
    name: 'Business',
    priceId: 'price_1Io6rtEVN107xxO1TmjlmOse',
    productId: 'prod_ItAqhYs3mClPrN',
    maxRequests: 500000,
    maxProjects: 5,
    maxModels: -1,
    maxWordlists: 10,
    characterLimit: 10000,
    softLimitPercentage: 0,
    maxRequestsPerMinute: 300,
    moderationDashboardIncluded: false,
    analyticsIncluded: false,
    membersIncluded: 3,
  },
  // 2023 plans
  LITE_2: {
    ...defaultPlanValues,
    id: 'LITE_2',
    name: 'Lite',
    maxRequests: 10000,
    maxProjects: 2,
    maxModels: -1,
    maxWordlists: 2,
    characterLimit: 10000,
    priceId: 'price_1N1Lw3EVN107xxO1aRZWZw97',
    priceIdYearly: 'price_1N1Lw3EVN107xxO1PDf01x0a',
    productId: 'prod_NmvnrPuyvf2hAU',
    softLimitPercentage: 0,
    maxRequestsPerMinute: 120,
    maxPaidRequests: 0,
    customModelsIncluded: 2,
    sumModelUsageEnabled: false,
    analyticsIncluded: false,
    moderationDashboardIncluded: false,
    paygAllowed: true,
    autoAnnotationsIncluded: 2000,
    membersIncluded: 3,
  },
  BUSINESS_3: {
    ...defaultPlanValues,
    id: 'BUSINESS_3',
    name: 'Business',
    maxRequests: 100000,
    maxProjects: 5,
    maxModels: -1,
    maxWordlists: 10,
    characterLimit: 10000,
    priceId: 'price_1N1LoaEVN107xxO1wWTYUVPt',
    priceIdYearly: 'price_1N1LoaEVN107xxO1LEXyA3st',
    productId: 'prod_NmvfSlLi3Yz2gq',
    softLimitPercentage: 0,
    maxRequestsPerMinute: 120,
    maxPaidRequests: 0,
    customModelsIncluded: 3,
    sumModelUsageEnabled: false,
    analyticsIncluded: true,
    moderationDashboardIncluded: true,
    paygAllowed: true,
    autoAnnotationsIncluded: 10000,
    membersIncluded: 3,
  },
  CUSTOM_CP_LEGACY: {
    //  this one should only allow toxicity, nsfw, and profanity, no custom models or entity recognition
    id: 'CUSTOM_CP_LEGACY',
    name: 'Club Penguin Legacy',
    maxRequests: 1000000,
    maxProjects: 5,
    maxModels: 3,
    maxWordlists: 10,
    characterLimit: 10000,
    priceId: 'price_1NuCscEVN107xxO1SZcQl7XF',
    productId: 'prod_Ohc6mxU39rlLH3',
    softLimitPercentage: 0,
    maxRequestsPerMinute: 1500,
    maxPaidRequests: 0,
    customModelsIncluded: 0,
    sumModelUsageEnabled: false,
    analyticsIncluded: true,
    moderationDashboardIncluded: true,
    paygAllowed: true,
    autoAnnotationsIncluded: 0,
    aiAgentsIncluded: 0,
    membersIncluded: 1,
  },
});

export const IS_DEV_STAGING =
  process.env.NODE_ENV === 'development' ||
  process.env.SETTING !== 'production';

export const IS_DEV = process.env.NODE_ENV === 'development';

export const PLANS = (
  IS_DEV_STAGING ? DEV_PLANS : PROD_PLANS
) as typeof PROD_PLANS;

export const demoUserId =
  process.env.NODE_ENV === 'development'
    ? '65d6b551a80b4658d3b225bc'
    : IS_DEV_STAGING
      ? 'null'
      : '623d7cc089cb430009df36db';

export const DETECTION_MODES = Object.freeze({
  NORMAL: 'NORMAL',
  SUSPICIOUS: 'SUSPICIOUS',
  PARANOID: 'PARANOID',
});

export const DEFAULT_FILTER = Object.freeze({
  [DATA_TYPES.EMAIL]: {
    masking: true,
    enabled: false,
    mode: DETECTION_MODES.NORMAL,
  },
  [DATA_TYPES.PHONE]: {
    masking: true,
    enabled: false,
    mode: DETECTION_MODES.NORMAL,
  },
  [DATA_TYPES.URL]: {
    masking: true,
    enabled: false,
    mode: DETECTION_MODES.NORMAL,
  },
});

export const DEMO_FILTER = Object.freeze({
  [DATA_TYPES.EMAIL]: {
    enabled: true,
    masking: true,
    mode: DETECTION_MODES.PARANOID,
  },
  [DATA_TYPES.PHONE]: {
    enabled: true,
    masking: true,
    mode: DETECTION_MODES.PARANOID,
  },
  [DATA_TYPES.URL]: {
    enabled: true,
    masking: true,
    mode: DETECTION_MODES.PARANOID,
  },
  [DATA_TYPES.ADDRESS]: {
    enabled: true,
    masking: true,
    mode: DETECTION_MODES.PARANOID,
  },
});

export type Model = {
  name: string;
  id: string;
  description: string;
  type: ModelType;
  experimental: boolean;
  tags: string[];
  comingSoon?: boolean;
  gpt3?: boolean;
  maxChars?: number;
  editableLabels?: boolean;
  flaggingUnsupported?: boolean;
  labels?: Readonly<{
    [label: string]: string;
  }>;
  languages:
    | {
        language: string;
        code: string;
      }[]
    | 'all'
    | 'not_supported'
    | 'custom';
};

const defaultAnalyzerLanguages = [
  { language: 'English', code: 'en' },
  { language: 'Spanish', code: 'es' },
  { language: 'French', code: 'fr' },
  { language: 'German', code: 'de' },
  { language: 'Italian', code: 'it' },
  { language: 'Portuguese', code: 'pt' },
  { language: 'Russian', code: 'ru' },
  { language: 'Japanese', code: 'ja' },
  { language: 'Indonesian', code: 'id' },
  { language: 'Chinese', code: 'zh' },
  { language: 'Dutch', code: 'nl' },
  { language: 'Polish', code: 'pl' },
  { language: 'Swedish', code: 'sv' },
];

const defaultMatcherLanguages = [
  { language: 'English', code: 'en' },
  { language: 'Spanish', code: 'es' },
  { language: 'French', code: 'fr' },
  { language: 'German', code: 'de' },
  { language: 'Italian', code: 'it' },
  { language: 'Portuguese', code: 'pt' },
  { language: 'Russian', code: 'ru' },
  { language: 'Arabic', code: 'ar' },
  { language: 'Hindi', code: 'hi' },
  { language: 'Japanese', code: 'ja' },
  { language: 'Chinese', code: 'zh' },
  { language: 'Korean', code: 'ko' },
];

export const MODELS: {
  [key: string]: Model;
} = Object.freeze({
  [ANALYSIS_TYPES.IMAGE]: {
    name: 'Image NSFW',
    id: ANALYSIS_TYPES.IMAGE,
    description: 'Detect nudity, violence, weapons, drugs and more in images.',
    type: ModelType.IMAGE_CLASSIFIER,
    experimental: false,
    labels: NsfwImageModerationLabelDescriptions,
    tags: [],
    languages: 'not_supported',
  },

  [ANALYSIS_TYPES.IMAGE_TEXT]: {
    name: 'Image Text',
    id: ANALYSIS_TYPES.IMAGE_TEXT,
    description: 'Detect text in images.',
    type: ModelType.IMAGE_CLASSIFIER,
    experimental: false,
    labels: TextImageModerationLabels,
    tags: [],
    languages: [
      { language: 'English', code: 'en' },
      { language: 'Spanish', code: 'es' },
      { language: 'French', code: 'fr' },
      { language: 'German', code: 'de' },
      { language: 'Italian', code: 'it' },
      { language: 'Portuguese', code: 'pt' },
      { language: 'Russian', code: 'ru' },
      { language: 'Arabic', code: 'ar' },
    ],
  },

  [ANALYSIS_TYPES.LANGUAGE]: {
    name: 'Language',
    id: ANALYSIS_TYPES.LANGUAGE,
    description:
      'Find out what language a text is written in. The classifier detects over 160 languages. It requires a minimum of 5-10 words to work reliably.',
    type: ModelType.CLASSIFIER,
    experimental: false,
    tags: ['analyzer', 'customer support'],
    flaggingUnsupported: true,
    languages: 'all',
  },

  [ANALYSIS_TYPES.TOXICITY]: {
    name: 'Toxicity',
    id: ANALYSIS_TYPES.TOXICITY,
    description: 'Detect swearing, racism, threats etc.',
    type: ModelType.CLASSIFIER,
    experimental: false,
    editableLabels: true,
    tags: ['analyzer', 'content moderation', 'customer support', 'chat'],
    labels: TOXICITY_LABELS,
    languages: defaultAnalyzerLanguages,
  },
  [ANALYSIS_TYPES.QUALITY]: {
    name: 'Spam',
    id: ANALYSIS_TYPES.QUALITY,
    description: 'Detect spam and insubstantial text.',
    type: ModelType.CLASSIFIER,
    experimental: false,
    editableLabels: true,
    tags: ['analyzer', 'content moderation', 'customer support'],
    labels: QUALITY_LABELS,
    languages: defaultAnalyzerLanguages,
  },
  [ANALYSIS_TYPES.PROPRIETY]: {
    name: 'Propriety',
    id: ANALYSIS_TYPES.PROPRIETY,
    description: 'Detect sexual and flirting language.',
    type: ModelType.CLASSIFIER,
    experimental: false,
    editableLabels: true,
    tags: ['analyzer', 'content moderation', 'customer support', 'chat'],
    labels: PROPRIETY_LABELS,
    languages: defaultAnalyzerLanguages,
  },
  [ANALYSIS_TYPES.NSFW]: {
    name: 'NSFW',
    id: ANALYSIS_TYPES.NSFW,
    description:
      'Detect unsafe and sensitive topics. Useful for detecting content that might be inappropriate for children. Sensitive topics include, but are not limited to, violence, weapons, drugs, politics, religion, etc.',
    type: ModelType.CLASSIFIER,
    experimental: false,
    comingSoon: false,
    gpt3: true,
    maxChars: 1000,
    editableLabels: true,
    tags: ['analyzer', 'content moderation', 'chat'],
    labels: NSFW_LABELS,
    languages: defaultAnalyzerLanguages,
  },
  [ANALYSIS_TYPES.SENTIMENT]: {
    name: 'Sentiment',
    id: ANALYSIS_TYPES.SENTIMENT,
    description: 'Label a text as Positive, Negative, or Neutral.',
    type: ModelType.CLASSIFIER,
    experimental: false,
    editableLabels: true,
    maxChars: 1000,
    tags: ['analyzer', 'customer support', 'chat'],
    labels: SENTIMENT_LABELS,
    languages: defaultAnalyzerLanguages,
  },
  [ANALYSIS_TYPES.SEXUAL]: {
    name: 'Sexual',
    id: ANALYSIS_TYPES.SEXUAL,
    description: 'Detect sexual and explicit content',
    type: ModelType.CLASSIFIER,
    experimental: false,
    editableLabels: true,
    maxChars: 1000,
    tags: ['analyzer', 'chat'],
    labels: SEXUAL_LABELS,
    languages: defaultAnalyzerLanguages,
  },

  [ANALYSIS_TYPES.DISCRIMINATION]: {
    name: 'Discrimination',
    id: ANALYSIS_TYPES.DISCRIMINATION,
    description: 'Detect discrimination against groups',
    type: ModelType.CLASSIFIER,
    experimental: false,
    editableLabels: true,
    maxChars: 1000,
    tags: ['analyzer', 'chat'],
    labels: DISCRIMINATION_LABELS,
    languages: defaultAnalyzerLanguages,
  },
  [ANALYSIS_TYPES.SELF_HARM]: {
    name: 'Self-harm',
    id: ANALYSIS_TYPES.SELF_HARM,
    description: 'Detect self-harm content',
    type: ModelType.CLASSIFIER,
    experimental: false,
    editableLabels: true,
    maxChars: 1000,
    tags: ['analyzer', 'chat'],
    labels: SELF_HARM_LABELS,
    languages: defaultAnalyzerLanguages,
  },
  [ANALYSIS_TYPES.VIOLENCE]: {
    name: 'Violence',
    id: ANALYSIS_TYPES.VIOLENCE,
    description: 'Detect violent content',
    type: ModelType.CLASSIFIER,
    experimental: false,
    editableLabels: true,
    maxChars: 1000,
    tags: ['analyzer', 'chat'],
    labels: VIOLENCE_LABELS,
    languages: defaultAnalyzerLanguages,
  },
  [ANALYSIS_TYPES.PII]: {
    name: 'PII',
    id: ANALYSIS_TYPES.PII,
    description:
      'Detect if text contains personally identifiable information (PII). PII can be email addresses, phone numbers, names, addresses, social security numbers, passport numbers, credit card numbers, IP addresses, ages, passwords, and more.',
    type: ModelType.CLASSIFIER,
    experimental: false,
    editableLabels: true,
    maxChars: 1000,
    tags: ['analyzer', 'chat'],
    labels: PII_LABEL_DECSCRIPTIONS,
    languages: defaultAnalyzerLanguages,
  },
  [DATA_TYPES.EMAIL]: {
    name: 'Email',
    id: DATA_TYPES.EMAIL,
    description:
      "Detect email addresses in unstructured text. Works on a contextual level and also detects attempts to obfuscate the email like 'example(at)email(dot)com'. Can be used to detect, mask or extract email addresses.",
    type: ModelType.ENTITY_EXTRACTOR,
    experimental: false,
    tags: ['privacy', 'content moderation', 'extract', 'customer support'],
    languages: defaultMatcherLanguages,
  },
  [DATA_TYPES.PHONE]: {
    name: 'Phone',
    id: DATA_TYPES.PHONE,
    description:
      "Detect phone numbers from most countries. Works on a contextual level and also detects attempts to obfuscate the number like 'one two three' etc. Can be used to detect, mask or extract phone numbers.",
    type: ModelType.ENTITY_EXTRACTOR,
    experimental: false,
    tags: ['privacy', 'content moderation', 'extract'],
    languages: defaultMatcherLanguages,
  },
  [DATA_TYPES.URL]: {
    name: 'URL',
    id: DATA_TYPES.URL,
    description:
      "Detect URLs in unstructured text. Works on a contextual level and also detects attempts to obfuscate the URL like 'www(dot)example(dot)com'. Can be used to detect, mask or extract URLs.",
    type: ModelType.ENTITY_EXTRACTOR,
    experimental: false,
    tags: ['content moderation', 'extract'],
    languages: defaultMatcherLanguages,
  },
  [DATA_TYPES.ADDRESS]: {
    name: 'Address',
    id: DATA_TYPES.ADDRESS,
    description:
      'Detect, hide and extract addresses or parts of an address. Detects addreses and components for most countries.',
    type: ModelType.ENTITY_EXTRACTOR,
    experimental: false,
    tags: ['privacy', 'extract'],
    languages: defaultMatcherLanguages,
  },
  [DATA_TYPES.NAME]: {
    name: 'Name',
    id: DATA_TYPES.NAME,
    description:
      'Detect, extract, or hide person names. Specify what parts of the name you wish to detect to hide family names only etc.',
    type: ModelType.ENTITY_EXTRACTOR,
    experimental: false,
    tags: ['privacy', 'extract', 'customer support'],
    languages: defaultMatcherLanguages,
  },
  [DATA_TYPES.USERNAME]: {
    name: 'Username',
    id: DATA_TYPES.USERNAME,
    description:
      'Detect, extract, or hide usernames. Fx. Instagram, Twitter or Facebook handles.',
    type: ModelType.ENTITY_EXTRACTOR,
    experimental: false,
    tags: ['content moderation', 'extract', 'customer support'],
    languages: defaultMatcherLanguages,
  },
  [DATA_TYPES.PROFANITY]: {
    name: 'Profanity',
    id: DATA_TYPES.PROFANITY,
    description: 'Detect and hide swearing and innapropriate words.',
    type: ModelType.ENTITY_EXTRACTOR,
    experimental: false,
    tags: ['chat', 'content moderation', 'extract'],
    languages: [
      {
        language: 'English',
        code: 'en',
      },
    ],
  },
  [DATA_TYPES.WORDLIST]: {
    name: 'Word (legacy)',
    id: DATA_TYPES.WORDLIST,
    description:
      'The word model has been replaced by adding wordlists directly to the project under Wordlists.',
    type: ModelType.ENTITY_EXTRACTOR,
    experimental: false,
    tags: ['chat', 'content moderation', 'extract'],
    languages: 'all',
  },
  [DATA_TYPES.SENSITIVE]: {
    name: 'Sensitive Number',
    id: DATA_TYPES.SENSITIVE,
    description:
      "Detect and hide a range of sensitive numbers like bank accounts, card, passwords and SSN's.",
    type: ModelType.ENTITY_EXTRACTOR,
    experimental: false,
    tags: ['privacy', 'extract', 'customer support'],
    languages: defaultMatcherLanguages,
  },
});

export const detection_levels = [
  {
    label: '🟢 Normal',
    value: DETECTION_MODES.NORMAL,
    description: 'Detect values that are spelled and formatted correctly.',
  },
  {
    label: '🟡 Suspicious',
    value: DETECTION_MODES.SUSPICIOUS,
    description: 'Also detect values that are mispelled or obfuscated.',
  },
  {
    label: '🔴 Paranoid',
    value: DETECTION_MODES.PARANOID,
    description:
      'Also detect values even if somewhat unsure about correctness.',
  },
];

export const name_components = [
  {
    label: 'Full Names',
    value: ['first', 'middle', 'last'],
    description: 'Detect whole names including first, middle, and last name.',
  },
  {
    label: 'Family and Middle Names',
    value: ['middle', 'last'],
    description: 'Detect last parts of names including middle and last name.',
  },
  {
    label: 'Family Names',
    value: ['last'],
    description: 'Only detect last names.',
  },
];

export const address_components = [
  {
    label: 'House, Unit & Level',
    value: ['level', 'unit', 'house_number', 'staircase', 'entrance'],
    description:
      'Building/street number, office, apartment, floor e.g. "10", "3rd Floor".',
  },
  {
    label: 'Road',
    value: ['house', 'road'],
    description: 'Street name e.g. "Abby Road", "5th Avenue".',
  },
  {
    label: 'Postcode',
    value: ['postcode', 'po_box'],
    description: 'Postal / zip codes e.g. "1234", "1A1 1A1".',
  },
  {
    label: 'City',
    value: ['suburb', 'city', 'city_district'],
    description:
      'Including suburbs, towns, villages, districts. "New York", "Paris" etc.',
  },
  {
    label: 'State & Region',
    value: ['country_region', 'state'],
    description: 'First-level administrative division, e.g. "California".',
  },
  {
    label: 'Country',
    value: ['country'],
    description:
      'Sovereign nations, e.g. "United States", "France", "Denmark".',
  },
  {
    label: 'World Region',
    value: ['world_region'],
    description: '"Europe", "West Indies", "Asia", etc.',
  },
];

export const sensitive_numbers_components = [
  {
    label: 'Personal Numbers',
    value: ['driver_id', 'ssn', 'passport_number'],
    description: 'Social security number, driver ID and passport numbers.',
  },
  {
    label: 'Bank Accounts',
    value: ['bank_account_number', 'bank_routing'],
    description: 'Acount and routing number of US bank accounts.',
  },
  {
    label: 'Payment Cards',
    value: ['credit_debit_number', 'credit_debit_expiry', 'credit_debit_cvv'],
    description: 'Both card number, expiry and CVV.',
  },
  {
    label: 'Passwords',
    value: ['pin', 'password'],
    description: 'Passwords and 4-digit pin codes.',
  },
  {
    label: 'Digital Addresses',
    value: ['ip_address', 'mac_address'],
    description: 'IPv4 and MAC addresses.',
  },
];

export const TOLERANCES = {
  [DETECTION_MODES.NORMAL]: 0.99,
  [DETECTION_MODES.SUSPICIOUS]: 0.75,
  [DETECTION_MODES.PARANOID]: 0.5,
};

export const MAX_TRAIN_EXAMPLE_CHARS = 1000;
export const TRAINING_ROWS_LIMIT = 5000;

export const PAY_AS_YOU_GO_PRICE_ID = IS_DEV_STAGING
  ? 'price_1N15DZEVN107xxO1D4LTWkjK'
  : 'price_1N1LiyEVN107xxO133c76jMA';

export const AI_AGENT_PRICE_ID = IS_DEV_STAGING
  ? 'price_1NXmoYEVN107xxO167GPVN0p'
  : 'price_1NXmf7EVN107xxO18LYaOyba';

export const TEAM_SEATS_PRICE_ID = IS_DEV_STAGING
  ? 'price_1P5dLTEVN107xxO1KFMzPhug'
  : 'price_1P5dIOEVN107xxO1f7Z83vCd';

export const MIXPANEL_TOKEN = IS_DEV_STAGING
  ? '5a6651e9d31c7e2d8930c40e4e1536cd'
  : 'b1f544f1321ffbf049e18aeb65c77391';

export const ENABLE_GTAG = !IS_DEV_STAGING;
export const ENABLE_CRISP = false;

export enum FILTER_MODEL_TYPE {
  AGENT = 'agent',
  CUSTOM = 'custom',
  BUILTIN = 'builtin',
  WORDLIST = 'wordlist',
  IMAGE = 'image',
}

export type FilterModel = {
  id: string;
  name: string;
  description: string | null;
  type: FILTER_MODEL_TYPE;
  status?: ModelStatus;
  modelType?: ModelType;
  experimental?: boolean;
  tags: string[];
  enabled: boolean;
  enabledForProject?: boolean;
  comingSoon?: boolean;
  createdAt?: Date;
  labels?: {
    label: string;
    description: string;
  }[];
};

export const webhookEnumTitleMap = {
  [WebhookType.QUEUE_ITEM_ACTION]: 'Queue item action',
  [WebhookType.QUEUE_ITEM_NEW]: 'New queue item',
  [WebhookType.QUEUE_ITEM_COMPLETED]: 'Queue item completed',
};
