export const DETECTED_LANGUAGES = Object.freeze([
  'ABKHAZIAN',
  'AFAR',
  'AFRIKAANS',
  'AKAN',
  'ALBANIAN',
  'AMHARIC',
  'ARABIC',
  'ARMENIAN',
  'ASSAMESE',
  'AYMARA',
  'AZERBAIJANI',
  'BASHKIR',
  'BASQUE',
  'BELARUSIAN',
  'BENGALI',
  'BIHARI',
  'BISLAMA',
  'BOSNIAN',
  'BRETON',
  'BULGARIAN',
  'BURMESE',
  'CATALAN',
  'CEBUANO',
  'CHEROKEE',
  'CORSICAN',
  'CROATIAN',
  'CZECH',
  'Chinese',
  'ChineseT',
  'DANISH',
  'DHIVEHI',
  'DUTCH',
  'DZONGKHA',
  'ENGLISH',
  'ESPERANTO',
  'ESTONIAN',
  'FAROESE',
  'FIJIAN',
  'FINNISH',
  'FRENCH',
  'FRISIAN',
  'GALICIAN',
  'GANDA',
  'GEORGIAN',
  'GERMAN',
  'GREEK',
  'GREENLANDIC',
  'GUARANI',
  'GUJARATI',
  'HAITIAN_CREOLE',
  'HAUSA',
  'HAWAIIAN',
  'HEBREW',
  'HINDI',
  'HMONG',
  'HUNGARIAN',
  'ICELANDIC',
  'IGBO',
  'INDONESIAN',
  'INTERLINGUA',
  'INTERLINGUE',
  'INUKTITUT',
  'INUPIAK',
  'IRISH',
  'ITALIAN',
  'JAVANESE',
  'Japanese',
  'KANNADA',
  'KASHMIRI',
  'KAZAKH',
  'KHASI',
  'KHMER',
  'KINYARWANDA',
  'KURDISH',
  'KYRGYZ',
  'Korean',
  'LAOTHIAN',
  'LATIN',
  'LATVIAN',
  'LIMBU',
  'LINGALA',
  'LITHUANIAN',
  'LUXEMBOURGISH',
  'MACEDONIAN',
  'MALAGASY',
  'MALAY',
  'MALAYALAM',
  'MALTESE',
  'MANX',
  'MAORI',
  'MARATHI',
  'MAURITIAN_CREOLE',
  'MONGOLIAN',
  'NAURU',
  'NDEBELE',
  'NEPALI',
  'NORWEGIAN',
  'NORWEGIAN_N',
  'NYANJA',
  'OCCITAN',
  'ORIYA',
  'OROMO',
  'PASHTO',
  'PEDI',
  'PERSIAN',
  'POLISH',
  'PORTUGUESE',
  'PUNJABI',
  'QUECHUA',
  'RHAETO_ROMANCE',
  'ROMANIAN',
  'RUNDI',
  'RUSSIAN',
  'SAMOAN',
  'SANGO',
  'SANSKRIT',
  'SCOTS',
  'SCOTS_GAELIC',
  'SERBIAN',
  'SESELWA',
  'SESOTHO',
  'SHONA',
  'SINDHI',
  'SINHALESE',
  'SISWANT',
  'SLOVAK',
  'SLOVENIAN',
  'SOMALI',
  'SPANISH',
  'SUNDANESE',
  'SWAHILI',
  'SWEDISH',
  'SYRIAC',
  'TAGALOG',
  'TAJIK',
  'TAMIL',
  'TATAR',
  'TELUGU',
  'THAI',
  'TIBETAN',
  'TIGRINYA',
  'TONGA',
  'TSONGA',
  'TSWANA',
  'TURKISH',
  'TURKMEN',
  'UIGHUR',
  'UKRAINIAN',
  'URDU',
  'UZBEK',
  'VENDA',
  'VIETNAMESE',
  'VOLAPUK',
  'WARAY_PHILIPPINES',
  'WELSH',
  'WOLOF',
  'XHOSA',
  'X_Buginese',
  'X_Gothic',
  'X_KLINGON',
  'X_PIG_LATIN',
  'YIDDISH',
  'YORUBA',
  'ZHUANG',
  'ZULU',
]);

export const SENTIMENT_LABELS = Object.freeze({
  POSITIVE: 'Positive sentiment.',
  NEGATIVE: 'Negative sentiment.',
  NEUTRAL: '',
});

export const TOXICITY_LABELS = Object.freeze({
  TOXICITY: 'Rude or disrespectful content.',
  PROFANITY: 'Swearing, curse words, and other obscene language.',
  DISCRIMINATION: 'Discrimination of race, religion, gender, etc.',
  SEVERE_TOXICITY: 'Very hateful and aggressive content.',
  INSULT: 'Negative comments about looks or personality etc.',
  THREAT: 'Content containing intention to harm or violence.',
  NEUTRAL: '',
});

export const QUALITY_LABELS = Object.freeze({
  UNSUBSTANTIAL: 'Trivial or short content.',
  INCOHERENT: 'Difficult to understand, nonsensical.',
  SPAM: 'Irrelevant and unsolicited commercial content.',
  NEUTRAL: '',
});

export const PROPRIETY_LABELS = Object.freeze({
  FLIRTATION: 'Pickup lines, compliments on appearance, etc.',
  SEXUALLY_EXPLICIT: 'References to sexual acts, body parts, etc.',
  NEUTRAL: '',
});

export const NSFW_LABELS = Object.freeze({
  UNSAFE: 'Sexual, hateful, profane, and inappropriate content.',
  SENSITIVE:
    'Mentions religion, politics, race, etc., but is neutral or positive.',
  NEUTRAL: '',
});

export const SEXUAL_LABELS = Object.freeze({
  SEXUAL: 'Sexual explicit content.',
  SEXUAL_MINOR: 'Sexual content involving a person under 18.',
  NEUTRAL: '',
});

export const SELF_HARM_LABELS = Object.freeze({
  SELF_HARM: 'Suicidal, cutting, and other self-harm content.',
  SELF_HARM_INTENT: 'Content containing intention to self-harm.',
  SELF_HARM_DIRECTION: 'Content containing direction and how-to.',
  NEUTRAL: '',
});

export const VIOLENCE_LABELS = Object.freeze({
  VIOLENCE: 'Content containing violence.',
  NEUTRAL: '',
});

export const DISCRIMINATION_LABELS = Object.freeze({
  DISCRIMINATION_MINORITY:
    'Discrimination of religion, ethnicity, sexual orientation, disabled, etc.',
  DISCRIMINATION: 'Discrimination of any group',
  NEUTRAL: '',
});
