export const shouldFlagResult = (
  filter?: {
    shouldFlag?: boolean;
    flagThreshold?: number;
    thresholds?: {
      [key: string]: number;
    };
  },
  label_scores?:
    | {
        [key: string]: number;
      }
    | {
        label: string;
        score: number;
      }[],
) => {
  const { shouldFlag = true, flagThreshold = 0.5, thresholds } = filter || {};

  if (shouldFlag === false && !thresholds) {
    return false;
  }

  // convert label_scores to array
  const label_scores_array = getLabelScoreArray(label_scores);

  // check if any of the labels is above the threshold
  const isAboveThreshold = label_scores_array?.some(({ label, score }) => {
    if (label?.toUpperCase() === 'NEUTRAL') {
      return false;
    }
    const threshold = thresholds?.[label] || flagThreshold;
    return score > threshold;
  });
  if (isAboveThreshold) {
    return true;
  }

  return false;
};

// typeguard to convert label_scores to array
export const getLabelScoreArray = (
  label_scores?:
    | {
        [key: string]: number;
      }
    | {
        label: string;
        score: number;
      }[],
) => {
  if (!label_scores) {
    return [];
  }
  if (Array.isArray(label_scores)) {
    return label_scores;
  } else {
    return Object.keys(label_scores || {}).map((label) => ({
      label,
      score: label_scores[label],
    }));
  }
};
